import { Machine as machine } from 'xstate';
import {
  isClaimWithMailboxDeliveryWarning,
  isClaimOutsideOfDeadline,
  isWarehouseDeliveryDateRequired,
  isNonManual,
  isClaimWithMissingTrackingData,
} from '../../parcel-finder/claims/claimsHelpers';

export default machine({
  key: 'claims_center_submit_claim',
  strict: true,
  initial: 'choose-claim-type',
  states: {
    'choose-claim-type': {
      on: {
        SELECT_DAMAGE: 'choose-damage-type',
        SELECT_INVESTIGATION: 'investigation',
        SELECT_MISSING_ITEM: 'missing-item',
      },
    },
    'choose-damage-type': {
      on: {
        SELECT_PARTIAL_DAMAGE: 'partial-damage',
        SELECT_WHOLE_DAMAGE: 'whole-damage',
      },
    },
    investigation: {
      initial: 'search-claim-info',
      states: {
        'search-claim-info': {
          on: {
            NEXT: [
              {
                target: 'investigation-delivered-to-mailbox',
                cond: isClaimWithMailboxDeliveryWarning,
              },
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'deadline-shipping-info',
                cond: isClaimWithMissingTrackingData,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'investigation-delivered-to-mailbox': {
          on: {
            NEXT: [
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: [
              {
                target: 'investigation-delivery-info',
                cond: (claim) => claim.isDelivered,
              },
              {
                target: 'invoice-info',
              },
            ],
          },
        },
        'investigation-delivery-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'whole-damage': {
      initial: 'search-claim-info',
      states: {
        'search-claim-info': {
          on: {
            NEXT: [
              {
                target: 'warehouse-delivery-confirmation-info',
                cond: isWarehouseDeliveryDateRequired,
              },
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'deadline-shipping-info',
                cond: isClaimWithMissingTrackingData,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: [
              {
                target: 'manual-deadline-check',
                cond: isNonManual,
              },
              {
                target: 'shipping-info',
              },
            ],
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'whole-damage-info',
          },
        },
        'whole-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'partial-damage': {
      initial: 'search-claim-info',
      states: {
        'search-claim-info': {
          on: {
            NEXT: [
              {
                target: 'warehouse-delivery-confirmation-info',
                cond: isWarehouseDeliveryDateRequired,
              },
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'deadline-shipping-info',
                cond: isClaimWithMissingTrackingData,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: [
              {
                target: 'manual-deadline-check',
                cond: isNonManual,
              },
              {
                target: 'shipping-info',
              },
            ],
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'partial-damage-info',
          },
        },
        'partial-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'missing-item': {
      initial: 'search-claim-info',
      states: {
        'search-claim-info': {
          on: {
            NEXT: [
              {
                target: 'warehouse-delivery-confirmation-info',
                cond: isWarehouseDeliveryDateRequired,
              },
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'deadline-shipping-info',
                cond: isClaimWithMissingTrackingData,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: [
              {
                target: 'manual-deadline-check',
                cond: isNonManual,
              },
              {
                target: 'shipping-info',
              },
            ],
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'missing-item-info',
          },
        },
        'missing-item-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
  },
});
